import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { ABOUT, DELETE_ABOUT_IMAGE, STORAGE } from 'src/constants/url';
import ImageUploading from 'react-images-uploading';

const UpdateAbout = ({ reload, setReload, i }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');
  const [message, setMessage] = useState({ open: false, text: '', type: '' });
  const [images, setImages] = useState([]);
  const [logo, setLogo] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);

  // Initialize state values with the provided 'i' prop
  const [titleEn, setTitleEn] = useState(i?.translations?.title?.en || '');
  const [titleAR, setTitleAR] = useState(i?.translations?.title?.ar || '');
  const [titleKU, setTitleKU] = useState(i?.translations?.title?.ku || '');
  const [bioEn, setBioEn] = useState(i?.translations?.bio?.en || '');
  const [bioAR, setBioAR] = useState(i?.translations?.bio?.ar || '');
  const [bioKU, setBioKU] = useState(i?.translations?.bio?.ku || '');
  const [address, setAddress] = useState(i?.address || '');
  const [wifi, setWifi] = useState(i?.wifi || '');
  const [tel, setTel] = useState(i?.tel || '');
  const [facebook, setFacebook] = useState(i?.facebook || '');
  const [instagram, setInstagram] = useState(i?.instagram || '');
  const [youtube, setYoutube] = useState(i?.youtube || '');
  const [whatsapp, setWhatsapp] = useState(i?.whatsapp || '');
  const [snapchat, setSnapchat] = useState(i?.snapchat || '');
  const [tiktok, setTiktok] = useState(i?.tiktok || '');
  const [showImages, setShowImages] = useState(i?.images || []);
  const [showLogo, setShowLogo] = useState(i?.logo ? `${STORAGE}${i.logo}` : null);
  const [showCoverPhoto, setShowCoverPhoto] = useState(i?.cover_photo ? `${STORAGE}${i.cover_photo}` : null);

  useEffect(() => {
    setTitleEn(i?.translations?.title?.en || '');
    setTitleAR(i?.translations?.title?.ar || '');
    setTitleKU(i?.translations?.title?.ku || '');
    setBioEn(i?.translations?.bio?.en || '');
    setBioAR(i?.translations?.bio?.ar || '');
    setBioKU(i?.translations?.bio?.ku || '');
    setAddress(i?.address || '');
    setWifi(i?.wifi || '');
    setTel(i?.tel || '');
    setFacebook(i?.facebook || '');
    setInstagram(i?.instagram || '');
    setYoutube(i?.youtube || '');
    setWhatsapp(i?.whatsapp || '');
    setSnapchat(i?.snapchat || '');
    setTiktok(i?.tiktok || '');
    setShowImages(i?.images || []);
    setShowLogo(i?.logo ? `${STORAGE}${i.logo}` : null);
    setShowCoverPhoto(i?.cover_photo ? `${STORAGE}${i.cover_photo}` : null);
  }, [i]);

  const maxNumber = 70;
  const onChange = (imageList) => {
    setImages(imageList);
  };
  const onChangeLogo = (imageList) => {
    setLogo(imageList[0] ? imageList[0] : null);
    setShowLogo(imageList[0] ? imageList[0].data_url : null);
  };
  const onChangeCoverPhoto = (imageList) => {
    setCoverPhoto(imageList[0] ? imageList[0] : null);
    setShowCoverPhoto(imageList[0] ? imageList[0].data_url : null);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setImages([]);
    setLogo(null);
    setCoverPhoto(null);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage({ open: false, text: '', type: '' });
  };

  const UpdateAboutItem = async () => {
    setLoading(true);
    const formData = new FormData();

    images.forEach((image, index) => {
      formData.append(`images[${index}]`, image.file);
    });

    if (logo) {
      formData.append('logo', logo.file);
    }

    if (coverPhoto) {
      formData.append('cover_photo', coverPhoto.file);
    }

    formData.append('title[en]', titleEn);
    formData.append('title[ar]', titleAR);
    formData.append('title[ku]', titleKU);
    formData.append('bio[en]', bioEn);
    formData.append('bio[ar]', bioAR);
    formData.append('bio[ku]', bioKU);
    formData.append('address', address);
    formData.append('wifi', wifi);
    formData.append('tel', tel);
    formData.append('facebook', facebook);
    formData.append('instagram', instagram);
    formData.append('youtube', youtube);
    formData.append('snapchat', snapchat);
    formData.append('tiktok', tiktok);
    formData.append('whatsapp', whatsapp);

    try {
      const response = await axios.post(`${ABOUT}/1?_method=PUT`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setMessage({ open: true, text: 'Information updated successfully', type: 'success' });
        setTimeout(() => {
          setReload(!reload);
        }, 1500);
        setOpen(false);
      }
    } catch (error) {
      setMessage({
        open: true,
        text: error.response?.data?.message || 'An error occurred while updating the information.',
        type: 'error',
      });
    } finally {
      setLoading(false);
      setImages([]);
      setLogo(null);
      setCoverPhoto(null);
    }
  };

  const deleteImage = async (id) => {
    document.body.style.cursor = 'wait';
    try {
      await axios.delete(`${DELETE_ABOUT_IMAGE}${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      setMessage({
        open: true,
        text: error.response?.data?.message || 'An error occurred while deleting the image.',
        type: 'error',
      });
    } finally {
      setReload(!reload);
      setOpen(false);
      document.body.style.cursor = 'default';
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen} className="btn-bg border-0 text-white">
        <ModeEditOutlineOutlinedIcon /> Update information
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Update information</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={12} className="mb-2">
              <ImageUploading value={images} onChange={onChange} dataURLKey="data_url" maxNumber={maxNumber} multiple>
                {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                  <div className="upload__image-wrapper position-relative mb-3">
                    <button
                      className="btn-add w-100 d-block text-white border-0 py-2"
                      style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                      type="button"
                    >
                      Upload Images
                    </button>
                    {imageList.length > 0 &&
                      imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image['data_url']} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button onClick={() => onImageRemove(index)} className="bg-danger border-0 text-white position-absolute bottom-0 end-0">
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    {showImages.length > 0 &&
                      showImages.map((item, index) => (
                        <div key={index} className="position-relative mt-2 mb-2">
                          <img src={`${STORAGE}${item.image}`} width="100%" />
                          <button onClick={() => deleteImage(item.id)} className="bg-danger border-0 text-white position-absolute bottom-0 end-0">
                            Remove
                          </button>
                        </div>
                      ))}
                  </div>
                )}
              </ImageUploading>
            </Grid>

            {/* Logo Upload */}
            <Grid item xs={12} md={12} className="mb-2">
              <ImageUploading value={logo ? [logo] : []} onChange={onChangeLogo} dataURLKey="data_url">
                {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                  <div className="upload__image-wrapper position-relative mb-3">
                    <button
                      className="btn-add w-100 d-block text-white border-0 py-2"
                      style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                      type="button"
                    >
                      Upload Logo
                    </button>
                    {imageList.length > 0 &&
                      imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image['data_url']} alt="Logo" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button onClick={() => onImageRemove(index)} className="bg-danger border-0 text-white position-absolute bottom-0 end-0">
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    {showLogo && (
                      <div className="position-relative mt-2 mb-2">
                        <img src={showLogo} width="100%" />
                        <button
                          onClick={() => {
                            setShowLogo(null);
                            setLogo(null);
                          }}
                          className="bg-danger border-0 text-white position-absolute bottom-0 end-0"
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </ImageUploading>
            </Grid>

            {/* Cover Photo Upload */}
            <Grid item xs={12} md={12} className="mb-2">
              <ImageUploading value={coverPhoto ? [coverPhoto] : []} onChange={onChangeCoverPhoto} dataURLKey="data_url">
                {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                  <div className="upload__image-wrapper position-relative mb-3">
                    <button
                      className="btn-add w-100 d-block text-white border-0 py-2"
                      style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                      type="button"
                    >
                      Upload Cover Photo
                    </button>
                    {imageList.length > 0 &&
                      imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image['data_url']} alt="Cover" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button onClick={() => onImageRemove(index)} className="bg-danger border-0 text-white position-absolute bottom-0 end-0">
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    {showCoverPhoto && (
                      <div className="position-relative mt-2 mb-2">
                        <img src={showCoverPhoto} width="100%" />
                        <button
                          onClick={() => {
                            setShowCoverPhoto(null);
                            setCoverPhoto(null);
                          }}
                          className="bg-danger border-0 text-white position-absolute bottom-0 end-0"
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </ImageUploading>
            </Grid>

            <Grid item xs={12} md={12} className="mb-2">
              <TextField
                id="filled-basic"
                label="Title in English"
                value={titleEn}
                variant="filled"
                onChange={(e) => setTitleEn(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} className="mb-2">
              <TextField
                id="filled-basic"
                label="Title in Arabic"
                value={titleAR}
                variant="filled"
                onChange={(e) => setTitleAR(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} className="mb-2">
              <TextField
                id="filled-basic"
                label="Title in Kurdish"
                value={titleKU}
                variant="filled"
                onChange={(e) => setTitleKU(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} className="mb-2">
              <TextField
                id="filled-multiline-static"
                label="Bio in English"
                multiline
                rows={4}
                value={bioEn}
                variant="filled"
                fullWidth
                onChange={(e) => setBioEn(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12} className="mb-2">
              <TextField
                id="filled-multiline-static"
                label="Bio in Arabic"
                multiline
                rows={4}
                value={bioAR}
                variant="filled"
                fullWidth
                onChange={(e) => setBioAR(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12} className="mb-2">
              <TextField
                id="filled-multiline-static"
                label="Bio in Kurdish"
                multiline
                rows={4}
                value={bioKU}
                variant="filled"
                fullWidth
                onChange={(e) => setBioKU(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4} className="mb-2">
              <TextField
                id="filled-basic"
                label="Address"
                value={address}
                variant="filled"
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} className="mb-2">
              <TextField
                id="filled-basic"
                label="Telephone"
                value={tel}
                variant="filled"
                onChange={(e) => setTel(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} className="mb-2">
              <TextField
                id="filled-basic"
                label="Wifi"
                value={wifi}
                variant="filled"
                onChange={(e) => setWifi(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} className="mb-2">
              <TextField
                id="filled-basic"
                label="Facebook"
                value={facebook}
                variant="filled"
                onChange={(e) => setFacebook(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} className="mb-2">
              <TextField
                id="filled-basic"
                label="Instagram"
                value={instagram}
                variant="filled"
                onChange={(e) => setInstagram(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} className="mb-2">
              <TextField
                id="filled-basic"
                label="Youtube"
                value={youtube}
                variant="filled"
                onChange={(e) => setYoutube(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} className="mb-2">
              <TextField
                id="filled-basic"
                label="Whatsapp"
                value={whatsapp}
                variant="filled"
                onChange={(e) => setWhatsapp(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} className="mb-2">
              <TextField
                id="filled-basic"
                label="Snapchat"
                value={snapchat}
                variant="filled"
                onChange={(e) => setSnapchat(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} className="mb-2">
              <TextField
                id="filled-basic"
                label="Tiktok"
                value={tiktok}
                variant="filled"
                onChange={(e) => setTiktok(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="mb-4">
          {loading ? (
            <Button className=" btn-bg">
              <CircularProgress style={{ color: '#fff' }} size={30} />
            </Button>
          ) : (
            <Button className="btn-bg  text-white fs-6" onClick={UpdateAboutItem}>
              Save
            </Button>
          )}
          <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={message.open} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
        <Alert
          onClose={handleCloseMessage}
          severity={message.type}
          sx={{ width: '100%' }}
          style={{ background: message.type === 'success' ? 'rgb(46, 125, 50)' : 'rgb(211, 47, 47)', color: '#fff' }}
        >
          {message.text}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UpdateAbout;
