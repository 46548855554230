import {
    TextField,
    CircularProgress,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Alert,
    Snackbar
} from '@mui/material';
import { useState, useEffect } from "react";
import axios from "axios";
import { CURRENCY } from "src/constants/url";

const UpdateCurrency = ({ open, handleClose, reload, setReload, currentCurrency }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const [error, setError] = useState(false);
    const [erroralert, showErrorAlert] = useState([]);
    const token = localStorage.getItem('token');
    const [currency, setCurrency] = useState(currentCurrency?.currency || '');
    const [rate, setRate] = useState(currentCurrency?.exchange_rate || '');

    useEffect(() => {
        if (currentCurrency) {
            setCurrency(currentCurrency.currency);
            setRate(currentCurrency.exchange_rate);
        }
    }, [currentCurrency]);

    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(false);
    };

    const updateCurrencyForm = () => {
        setLoading(true);
        const data = {
            exchange_rate: rate
        }
        axios
            .put(`${CURRENCY}/${currentCurrency.id}`, data, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setMessage(true);
                    setTimeout(() => {
                        setReload(!reload);
                    }, 1500);
                    handleClose();
                    setError(false);
                }
            })
            .catch((error) => {
                setError(true);
                showErrorAlert(error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description" >
            <DialogTitle id="alert-dialog-title">Update Currency</DialogTitle>
            <DialogContent>
                {error && (
                    <Alert severity="error" className="mb-4">
                        <AlertTitle>Error</AlertTitle>
                        <p>{erroralert}</p>
                    </Alert>
                )}
                <TextField
                    id="filled-basic"
                    label="Currency Name"
                    value={currency}
                    variant="filled"
                    fullWidth
                    className="mb-2"
                    disabled
                />

                <TextField
                    id="filled-basic"
                    label="Exchange Rate"
                    value={rate}
                    variant="filled"
                    fullWidth
                    onChange={(e) => setRate(e.target.value)}
                    className="mb-2"
                    disabled={currency === "IQD"}
                />
            </DialogContent>
            <DialogActions className="mb-4">
                {loading ? (
                    <Button className=" btn-bg">
                        <CircularProgress style={{ color: '#fff' }} size={30} />
                    </Button>
                ) : (
                    <Button className="btn-bg  text-white fs-6" onClick={updateCurrencyForm} disabled={currency === "IQD"}>
                        Save
                    </Button>
                )}
                <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
                    Close
                </Button>
            </DialogActions>
            <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
                <Alert
                    onClose={handleCloseMessage}
                    severity="success"
                    sx={{ width: '100%' }}
                    style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
                >
                    Success! Currency Updated Successfully
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default UpdateCurrency;
