import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import {STATISTICS} from '../constants/url';

// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const [statistics, setStatistics] = useState(null);

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await axios.get(STATISTICS);
        setStatistics(response.data.data); // Access the 'data' field from the response
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    };

    fetchStatistics();
  }, []);

  if (!statistics) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title> Dashboard | Digi Dine </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Reservations" total={statistics.reservations} icon={'dashicons:food'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Orders Count" total={statistics.ordersCount} color="info" icon={'mdi:counter'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Imports" total={statistics.total_imports} color="warning" icon={'il:money'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Most Requested Items" total={statistics.mostRequestedItems.length} color="error" icon={'fad:repeat'} />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'Reservations', value: statistics.reservations },
                { label: 'Orders Count', value: statistics.ordersCount },
                { label: 'Total Imports', value: statistics.total_imports },
                // { label: 'Africa', value: statistics.mostRequestedItems.length },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid> */}

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              // subheader="(+43%) than last year"
              chartData={statistics.mostRequestedItems.map((item, index) => ({
                label: item.item_name || `Item ${index + 1}`,
                value: item.value || 0,
              }))}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
