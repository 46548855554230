import ScrollToTop from './components/scroll-to-top';
import './assets/css/style.css';
import './assets/toast_sound.mp3';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard/DashboardLayout';
import DashboardAppPage from './pages/DashboardAppPage';
import CategoryPage from './pages/categories/CategoryPage';
import LoginPage from './pages/LoginPage';
import PrivateRoutes from './PrivateRoutes';
import UserPage from './pages/user/UserPage';
import ItemsPage from './pages/items/ItemsPage';
import KitchenPage from './pages/kitchen/KitchenPage';
import TablesPage from './pages/tables/TablesPage';
import Page404 from './pages/Page404';
import AboutPage from './pages/about/AboutPage';
import { onMessageListener } from './firebase';
import { useState, useEffect } from 'react';
import toast, { Toaster, ToastBar } from 'react-hot-toast';
import { NOTIFICATIONS } from './constants/url';
import axios from 'axios';
import OrderPage from './pages/oerder/OrderPage';
import Unauthorized from './pages/Unauthorized';
import RequireAuth from './components/RequireAuth';
// import AddLanguage from './pages/languages/AddLanguage';
import CurrencyPage from './pages/currency/CurrencyPage';
import useSound from 'use-sound';
import sound from './assets/toast_sound.mp3';
import FilesPage from './pages/files/FilesPage';
import BackupPage from './pages/backup/BackupPage';

export default function App() {
  const [playSound] = useSound(sound);
  const token = localStorage.getItem('token');
  const [notification, setNotification] = useState({ body: '' });
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  const notify = () => toast(<ToastDisplay />);
  function ToastDisplay() {
    return (
      <div>
        <p style={{ wordBreak: 'break-all' }}>{notification?.body}</p>
      </div>
    );
  }

  const FetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await axios.get(NOTIFICATIONS, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
      if (response.status === 200) {
        setNotifications(response.data.data);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem('token');
      } else {
        console.error('Error fetching notifications:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (notification?.body) {
      notify();
    }
  }, [notification]);

  useEffect(() => {
    if (!('Notification' in window)) {
      console.log('Browser does not support desktop notification');
    } else {
      Notification.requestPermission();
    }
    FetchNotifications();
  }, []);

  onMessageListener()
    .then((payload) => {
      playSound();
      setNotifications([
        ...notifications,
        {
          body: payload.data.body,
          id: payload.messageId,
        },
      ]);
      setNotification({
        body: payload.data.body,
        id: payload.messageId,
      });
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <>
      <Toaster
        position="left-bottom"
        toastOptions={{
          className: '',
          style: {
            width: '500px',
            padding: '16px',
            color: '#fff',
            background: '#CDA34F',
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== 'loading' && (
                  <button className="toast-close" onClick={() => toast.dismiss(t.id)}>
                    X
                  </button>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <Routes>
          <Route path="/home" element={<Navigate replace to="/dashboard/app" />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard" element={<DashboardLayout notifications={notifications} loading={loading} />}>
              <Route path="app" element={<DashboardAppPage />} />
              <Route element={<RequireAuth allowedRoles={['Show-About']} />}>
                <Route path="about" element={<AboutPage />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={['show-Category']} />}>
                <Route path="categories" element={<CategoryPage />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={['show-Item']} />}>
                <Route path="items" element={<ItemsPage />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={['show-StaffSection']} />}>
                <Route path="staff-section" element={<KitchenPage />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={['show-User']} />}>
                <Route path="users" element={<UserPage />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={['show-Table']} />}>
                <Route path="tables" element={<TablesPage />} />
              </Route>
              <Route>
                <Route path="files" element={<FilesPage />} />
              </Route>
              <Route>
                <Route path="backUp" element={<BackupPage />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={['Show-Order']} />}>
                <Route path="orders" element={<OrderPage />} />
              </Route>
              <Route path="currency" element={<CurrencyPage />} />
            </Route>
          </Route>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route element={<LoginPage />} path="/login" />
          <Route element={<Page404 />} path="*" />
          <Route path="unauthorized" element={<Unauthorized />} />
        </Routes>
      </ThemeConfig>
    </>
  );
}
