import {
  TextField,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  //   DialogContentText,
  DialogTitle,
  Alert,
  AlertTitle,
  Snackbar,
  Skeleton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ADD_LANG, KITCHEN_SECTION } from 'src/constants/url';

const CreateKitchen = ({ reload, setReload }) => {
  const [all, setAll] = useState([]);
  const [loadingLang, setLoadingLang] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');
  const [nameEn, setNameEn] = useState('');
  const [nameAr, setNameAr] = useState('');
  const [nameKu, setNameKu] = useState('');
  const [error, setError] = useState(false);
  const [erroralert, showErrorAlert] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage(false);
  };
  const FetchAllLang = async () => {
    setLoadingLang(true)
    await axios
      .get(ADD_LANG, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAll(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setLoadingLang(false)
  };
  useEffect(() => {
    FetchAllLang();

  }, []);
  const handleChangeInputs = (e, id) => {
    const { value } = e.target;
    setAll((inputs) =>
      inputs?.map((list, index) =>
        list.id === id ? { ...list, inputs: value } : list,
      )
    );
  };
  const CreateStaffSectionForm = () => {
    setLoading(true);
    const formData = new FormData();
    all.map((item) => formData.append(`name[${item.code}]`, [item.inputs]));
    axios
      .post(KITCHEN_SECTION, formData, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setMessage(true);
          setTimeout(() => {
            setReload(!reload);
          }, 1500);
          setOpen(false);
          setError(false);
        }
      })

      .catch((error) => {
        setError(true);
        showErrorAlert(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button variant="contained" onClick={handleClickOpen} className="btn-add">
        <AddIcon /> New Staff Section
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Create Staff Section</DialogTitle>
        <DialogContent>

          {error ? (
            <Alert severity="error" className="mb-4">
              <AlertTitle>Error</AlertTitle>
              <p>{erroralert}</p>
            </Alert>
          ) : (
            ''
          )}
          {loadingLang ? <Skeleton animation="wave" width="300px" /> :
            <>
              {all.length > 0 && all.map((item) => (
                <TextField
                  id="filled-basic"
                  label={`Staff Section Name ${item.title}`}
                  value={item.inputs}
                  key={item.code}
                  variant="filled"
                  fullWidth
                  onChange={(e) => handleChangeInputs(e, item.id)}
                  className="mb-2"
                />
              ))}
            </>
          }

        </DialogContent>
        <DialogActions className="mb-4">
          {loading ? (
            <Button className=" btn-bg">
              <CircularProgress style={{ color: '#fff' }} size={30} />
            </Button>
          ) : (
            <Button className="btn-bg  text-white fs-6" onClick={CreateStaffSectionForm}>
              Save
            </Button>
          )}
          <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: '100%' }}
          style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
        >
          success! Item Added Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateKitchen;
