import axios from 'axios';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TABLE_LOGOUT } from 'src/constants/url';

const LogoutTable = ({ token, id, reload, setReload, label }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    if (token != null) {
      await axios
        .post(
          `${TABLE_LOGOUT}/${id}`,
          {},
          {
            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setReload(!reload);
          } else if (response.status === 401) {
            navigate('/', { replace: true });
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    } else {
      navigate('/login');
    }
  };

  return (
    <Button variant="contained" color="success" onClick={handleLogout}>
      {label}
    </Button>
  );
};

export default LogoutTable;
