import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { ORDERS, STORAGE } from 'src/constants/url';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import UpdateOrder from './UpdateOrder';
import Label from 'src/components/label/Label';
import useStateRef from 'react-usestateref';
import Pagination from '@mui/material/Pagination';
import moment from 'moment';

const OrderPage = () => {
  const token = localStorage.getItem('token');
  const roles = localStorage.getItem('permissions');
  const [allOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [ordersToShow, setOrdersToShow, ordersToShowRef] = useStateRef([]);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const navigate = useNavigate();
  const FetchOrders = async () => {
    setLoading(true);
    await axios
      .get(`${ORDERS}?page=${page}`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAllOrders(response.data.data);
          setOrdersToShow(response.data.data);
          setTotalPage(response.data.meta.last_page);
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          navigate('/login');
          localStorage.removeItem('token');
        }
      });

    setLoading(false);
  };

  const [tabValue, setTabValue, tabValueRef] = useStateRef('All');

  const handleChangeTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  function filterHandler() {
    switch (tabValueRef.current) {
      case 'All':
        setOrdersToShow(allOrders);
        break;
      case 'New':
        setOrdersToShow(allOrders.filter((item) => item.order_status === 'new'));
        break;
      case 'Delivered':
        setOrdersToShow(allOrders.filter((item) => item.order_status === 'delivered'));
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    FetchOrders();
  }, [reload, page]);

  return (
    <>
      <Helmet>
        <title> Orders | Digi Dine </title>
      </Helmet>
      <Container>
        <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginBottom: '1rem' }}>
          <Typography variant="h4" gutterBottom>
            Orders
          </Typography>
          <Tabs
            value={tabValue}
            onChange={handleChangeTabValue}
            sx={{ '& .Mui-selected': { color: '#37d0d4' } }}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#37d0d4',
              },
            }}
          >
            <Tab value="All" label="All" onClick={filterHandler} />
            <Tab value="New" label="New" onClick={filterHandler} />
            <Tab value="Delivered" label="Delivered" onClick={filterHandler} />
          </Tabs>
        </Stack>
        {loading ? (
          <Skeleton animation="wave" height={500} variant="rectangular" />
        ) : (
          <>
            {ordersToShowRef.current.length > 0 ? (
              ordersToShowRef.current.map((item) => (
                <div className="box-cat position-relative rounded shadow text-center mt-5" key={item.id} id={item.id}>
                  <Typography variant="h4" className="order-num">
                    {item.id}
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={4} md={3}>
                      <Typography variant="h6">
                        Table Number
                        <p>{item.table_number}</p>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                      <Typography variant="h6">
                        Last Order
                        <br />
                        {item.last_order === 1 ? (
                          <Label
                            variant="ghost"
                            sx={{
                              color: 'green',
                            }}
                          >
                            Yes
                          </Label>
                        ) : (
                          <Label
                            variant="ghost"
                            sx={{
                              color: 'orange',
                            }}
                          >
                            No
                          </Label>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                      <Typography variant="h6">
                        Order Status
                        <p>{item.order_status}</p>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                      <Typography variant="h6">
                        Total Cost
                        <p>{item.total_cost}</p>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                      <Typography variant="h6">
                        Order Date
                        <p>{moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a')}</p>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h6">Item Specifications</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Item Name</TableCell>
                              <TableCell align="center">Item Price</TableCell>
                              <TableCell align="center">Item Offer Price</TableCell>
                              <TableCell align="center">Item Quantity</TableCell>
                              <TableCell align="center">Item Weight</TableCell>
                              <TableCell align="center">Item Tags</TableCell>
                              <TableCell align="center">Item Notes</TableCell>
                              <TableCell align="center">Item Image</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {item.Items.length > 0
                              ? item.Items.map((i, index) => (
                                  <TableRow key={index}>
                                    <TableCell align="center">{i.translations.name.en}</TableCell>
                                    <TableCell align="center">{i.price}</TableCell>
                                    <TableCell align="center">{i.offer_price != null ? i.offer_price : '--'}</TableCell>
                                    <TableCell align="center">{i.pivot.quantity}</TableCell>
                                    <TableCell align="center">{i.weight}</TableCell>
                                    <TableCell align="center">
                                      <Stack spacing={1}>
                                        <Label
                                          variant="ghost"
                                          sx={{
                                            color: i.is_trending != 0 ? 'green' : 'red',
                                          }}
                                        >
                                          trending: {i.is_trending != 0 ? 'yes' : 'no'}
                                        </Label>

                                        <Label
                                          variant="ghost"
                                          sx={{
                                            color: i.vegan != 0 ? 'green' : 'red',
                                          }}
                                        >
                                          vegan: {i.vegan != 0 ? 'yes' : 'no'}
                                        </Label>

                                        <Label
                                          variant="ghost"
                                          sx={{
                                            color: i.lactose_free != 0 ? 'green' : 'red',
                                          }}
                                        >
                                          lactose free: {i.lactose_free != 0 ? 'yes' : 'no'}
                                        </Label>

                                        <Label
                                          variant="ghost"
                                          sx={{
                                            color: i.gluten_free != 0 ? 'green' : 'red',
                                          }}
                                        >
                                          gluten free: {i.gluten_free != 0 ? 'yes' : 'no'}
                                        </Label>
                                      </Stack>
                                    </TableCell>
                                    <TableCell align="center">{i.pivot.notes}</TableCell>
                                    <TableCell align="center">
                                      <Link to={`${STORAGE}${i.image}`} target="_bank">
                                        <VisibilityIcon />
                                      </Link>{' '}
                                    </TableCell>
                                  </TableRow>
                                ))
                              : ''}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                  {roles.includes('update-Order') && (
                    <UpdateOrder reload={reload} setReload={setReload} id={item.id} item_order={item} />
                  )}
                </div>
              ))
            ) : (
              <Typography variant="h6" className="text-center" sx={{ mb: 5 }}>
                {' '}
                No orders yet{' '}
              </Typography>
            )}
          </>
        )}
        <Stack spacing={2} sx={{ padding: 2 }}>
          <Pagination
            page={page}
            onChange={handleChangePage}
            count={totalPages}
            variant="outlined"
            shape="rounded"
            sx={{ '& .MuiPagination-ul': { justifyContent: 'center' } }}
          />
        </Stack>
      </Container>
    </>
  );
};

export default OrderPage;
