import { Helmet } from 'react-helmet-async';
import {
  Typography,
  Container,
  Stack,
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { BACKUP_FILES, CREATE_BACKUP, DOWNLOAD_BACKUP, DELETE_BACKUP, RESTORE_BACKUP } from 'src/constants/url';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import BackupIcon from '@mui/icons-material/Backup';
import RestoreIcon from '@mui/icons-material/Restore';

const BackupPage = () => {
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);

  const fetchFiles = async () => {
    setLoading(true);
    if (token) {
      try {
        const response = await axios.get(BACKUP_FILES, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setFiles(response.data);
        } else if (response.status === 401) {
          navigate('/', { replace: true });
        }
      } catch (error) {
        console.log(error.response.data.message);
      }
    } else {
      navigate('/login');
    }
    setLoading(false);
  };

  const createBackup = async () => {
    setLoading(true);
    if (token) {
      try {
        const response = await axios.post(CREATE_BACKUP, {}, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setReload(!reload);
        }
      } catch (error) {
        console.log(error.response.data.message);
      }
    }
    setLoading(false);
  };

  const deleteFile = async (fileName) => {
    if (token) {
      try {
        const response = await axios.delete(`${DELETE_BACKUP}/${fileName}`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setReload(!reload);
        }
      } catch (error) {
        console.log(error.response.data.message);
      }
    }
  };

  const restoreBackup = async (fileName) => {
    if (token) {
      try {
        const response = await axios.post(RESTORE_BACKUP, { fileName }, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          console.log('Backup restored successfully');
        }
      } catch (error) {
        console.log(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [reload]);

  return (
    <>
      <Helmet>
        <title>Backup Files | Digi Dine</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Backup Files
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<BackupIcon />}
            onClick={createBackup}
          >
            Create Backup
          </Button>
        </Stack>
        {loading ? (
          <Skeleton animation="wave" height={500} variant="rectangular" />
        ) : (
          <>
            {files.length > 0 ? (
              <TableContainer
                component={Paper}
                sx={{
                  '& .MuiPaper-root ': {
                    overflowX: 'scroll',
                  },
                }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">File Name</TableCell>
                      <TableCell align="center">Size</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {files.map((file, index) => {
                      const fileName = file.path.split('/').pop();
                      return (
                        <TableRow key={index}>
                          <TableCell align="center">{fileName}</TableCell>
                          <TableCell align="center">{file.size}</TableCell>
                          <TableCell align="center">{file.date}</TableCell>
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<VisibilityIcon />}
                              href={`${DOWNLOAD_BACKUP}/${fileName}`}
                              target="_blank"
                              sx={{ mr: 2 }}
                            >
                              Download
                            </Button>
                            <IconButton
                              color="secondary"
                              onClick={() => deleteFile(fileName)}
                              sx={{ mr: 2 }}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <IconButton
                              color="primary"
                              onClick={() => restoreBackup(fileName)}
                            >
                              <RestoreIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="h6" className="text-center" sx={{ mb: 5 }}>
                No files available
              </Typography>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default BackupPage;
