import { Container, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { CURRENCY } from "src/constants/url";
import CreateCurrency from "./CreateCurrency";
import UpdateCurrency from "./UpdateCurrency";
import EditIcon from '@mui/icons-material/Edit';

const CurrencyPage = () => {
    const token = localStorage.getItem('token');
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [currentCurrency, setCurrentCurrency] = useState(null); // Add state for current currency to be updated
    const [openUpdate, setOpenUpdate] = useState(false); // State to control update dialog visibility
    const navigate = useNavigate();

    const FetchCurrency = async () => {
        setLoading(true);
        await axios
            .get(CURRENCY, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setInfo(response.data.data);
                }
            })
            .catch((error) => {
                if (error.status === 401) {
                    navigate('/login');
                    localStorage.removeItem('token');
                }
            });

        setLoading(false);
    };

    useEffect(() => {
        FetchCurrency();
    }, [reload]);

    const handleEditClick = (currency) => {
        setCurrentCurrency(currency);
        setOpenUpdate(true);
    };

    const handleCloseUpdate = () => {
        setOpenUpdate(false);
        setCurrentCurrency(null);
    };

    return (
        <>
            <Helmet>
                <title> Currency | Digi Dine </title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Currency
                    </Typography>
                    <CreateCurrency reload={reload} setReload={setReload} />
                </Stack>
                {loading ? <Skeleton animation="wave" height={500} variant="rectangular" /> :
                    <>
                        {info.length > 0 ? (
                            <TableContainer
                                component={Paper}
                                sx={{
                                    '& .MuiPaper-root ': {
                                        overflowX: 'scroll',
                                    },
                                }}
                            >
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center"></TableCell>
                                            <TableCell align="center">Currency Name</TableCell>
                                            <TableCell align="center">Exchange Rate</TableCell>
                                            <TableCell align="center">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {info.map((item) => (
                                            <TableRow key={item.id} id={item.id}>
                                                <TableCell align="center">{item.id}</TableCell>
                                                <TableCell align="center">{item.currency}</TableCell>
                                                <TableCell align="center">{item.exchange_rate}</TableCell>
                                                <TableCell align="center">
                                                    {item.currency !== "IQD" && (
                                                        <IconButton onClick={() => handleEditClick(item)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Typography variant="h6" className="text-center" sx={{ mb: 5 }}>
                                No currency yet
                            </Typography>
                        )}
                    </>
                }
                {currentCurrency && (
                    <UpdateCurrency 
                        open={openUpdate} 
                        handleClose={handleCloseUpdate} 
                        reload={reload} 
                        setReload={setReload} 
                        currentCurrency={currentCurrency} 
                    />
                )}
            </Container>
        </>
    )
}

export default CurrencyPage;
