import {
  TextField,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  AlertTitle,
  Snackbar,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Skeleton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ImageUploading from 'react-images-uploading';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ADD_LANG, CATEGORIES } from 'src/constants/url';
import KitchenSection from '../KitchenSection';
import icon1 from '../../assets/default-category-icons/1.jpg';
import icon2 from '../../assets/default-category-icons/2.jpg';
import icon3 from '../../assets/default-category-icons/3.jpg';

const CreateCategory = ({ reload, setReload }) => {
  const [all, setAll] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [loadingLang, setLoadingLang] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');
  const [images, setImages] = useState([]);
  const [imagesIcon, setImagesIcon] = useState([]);
  const [error, setError] = useState(false);
  const [empty, setEmpty] = useState('');
  const [erroralert, showErrorAlert] = useState([]);
  const [kitchen_section, setkitchen_section] = useState('');

  const [DefaultIconValue, setDefaultIconValue] = useState();

  const handleChangeDefaultIconValue = (event) => {
    setDefaultIconValue(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage(false);
  };

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  const onChangeIcon = (imageList, addUpdateIndex) => {
    setImagesIcon(imageList);
  };
  const [value, setValue] = useState('');
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const FetchAllLang = async () => {
    setLoadingLang(true);
    await axios
      .get(ADD_LANG, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAll(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setLoadingLang(false);
  };
  useEffect(() => {
    FetchAllLang();
  }, []);

  const handleChangeInputs = (e, id) => {
    const { value } = e.target;
    setAll((inputs) =>
      inputs?.map((list, index) =>
        list.id === id ? { ...list, inputs: value } : list,
      )
    );
  };

  const resetForm = () => {
    setAll(all.map(item => ({ ...item, inputs: '' })));
    setkitchen_section('');
    setImages([]);
    setImagesIcon([]);
    setDefaultIconValue('');
    setError(false);
  };

  const CreateCategoryForm = () => {
    setLoading(true);
    const formData = new FormData();
    all.map((item) => formData.append(`name[${item.code}]`, [item.inputs]));
    formData.append('staff_sections_id', kitchen_section);
    if (images.length !== 0) {
      formData.append('image', images[0].file);
    }
    if (imagesIcon.length !== 0) {
      formData.append('icon', imagesIcon[0].file);
    }

    axios
      .post(CATEGORIES, formData, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        setMessage(true);
        setTimeout(() => {
          setReload(!reload);
        }, 1500);
        setOpen(false);
        setError(false);
        resetForm(); // Reset form after successful creation
      })
      .catch((error) => {
        setError(true);
        showErrorAlert(error.response.data.message);
        setEmpty('please add Staff section then try to add category');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button variant="contained" onClick={handleClickOpen} className="btn-add">
        <AddIcon /> New Category
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Create Category</DialogTitle>

        <DialogContent>
          <>
            {error ? (
              <Alert severity="error" className="mb-4">
                <AlertTitle>Error</AlertTitle>
                <p>{erroralert}</p>
                {kitchen_section !== null ? '' : empty}
              </Alert>
            ) : (
              ''
            )}
            <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
              {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                <div className="upload__image-wrapper position-relative mb-3">
                  <button
                    className="btn-add w-100 d-block text-white border-0 py-2"
                    style={isDragging ? { color: 'red' } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                    type="button"
                  >
                    Upload Image
                  </button>
                  {imageList.map((image, index) => (
                    <div key={index} className="image-item">
                      <img src={image['data_url']} alt="" width="100" />
                      <div className="image-item__btn-wrapper">
                        <button
                          onClick={() => onImageRemove(index)}
                          className="bg-danger border-0 text-white position-absolute bottom-0 end-0"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading>
            <ImageUploading value={imagesIcon} onChange={onChangeIcon} dataURLKey="data_url">
              {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                <div className="upload__image-wrapper position-relative mb-3">
                  <button
                    className="btn-add w-100 d-block text-white border-0 py-2"
                    style={isDragging ? { color: 'red' } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                    type="button"
                  >
                    Upload Icon
                  </button>
                  {imageList.map((image, index) => (
                    <div key={index} className="image-item">
                      <img src={image['data_url']} alt="" width="100" />
                      <div className="image-item__btn-wrapper">
                        <button
                          onClick={() => onImageRemove(index)}
                          className="bg-danger border-0 text-white position-absolute bottom-0 end-0"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading>
            {loadingLang ? (
              <Skeleton animation="wave" width="300px" />
            ) : (
              <>
                {all.length > 0 &&
                  all.map((item) => (
                    <TextField
                      id="filled-basic"
                      label={`Category Name ${item.title}`}
                      value={item.inputs}
                      key={item.code}
                      variant="filled"
                      fullWidth
                      onChange={(e) => handleChangeInputs(e, item.id)}
                      className="mb-2"
                    />
                  ))}
              </>
            )}
            <KitchenSection kitchen_section={kitchen_section} setkitchen_section={setkitchen_section} />
          </>
        </DialogContent>
        <DialogActions className="mb-4">
          {loading ? (
            <Button className="btn-bg">
              <CircularProgress style={{ color: '#fff' }} size={30} />
            </Button>
          ) : (
            <Button className="btn-bg  text-white fs-6" onClick={CreateCategoryForm}>
              Save
            </Button>
          )}

          <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: '100%' }}
          style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
        >
          Success! Category Added Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateCategory;
