import Skeleton from '@mui/material/Skeleton';
import {
  Typography,
  Container,
  Grid,
  Stack,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { PERIOD_LOGIN, USER } from 'src/constants/url';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import DeleteUser from './DeleteUser';
import AddUser from './AddUser';
import { useNavigate } from 'react-router-dom';
import UpdateUser from './UpdateUser';
import ChangePeriodLogin from './ChangePeriodLogin';

const UserPage = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const roles = localStorage.getItem('permissions');
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [period, setPeriod] = useState('');
  const [reload, setReload] = useState(false);

  const FetchUsers = async () => {
    setLoading(true);
    await axios
      .get(USER, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setUsers(response.data.data);
        } else if (response.status === 401) {
          navigate('/', { replace: true });
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const FetchUserLoginPeriod = async () => {
    await axios
      .get(PERIOD_LOGIN, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setPeriod(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };
  useEffect(() => {
    FetchUsers();
    FetchUserLoginPeriod();
  }, [reload]);

  return (
    <>
      <Helmet>
        <title> Users | Digi Dine </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          {roles.includes('create-User') && <AddUser setReload={setReload} reload={reload} />}
        </Stack>
        {loading ? (
          <Skeleton animation="wave" height={500} variant="rectangular" />
        ) : (
          <>
            {users.length > 0 ? (
              <>
                {period !== null ? (
                  <>
                    {roles.includes('change-JWT-TTL') && (
                      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h5">
                          Login Access Period: <span>{period.JWT_TTL} minutes</span>
                        </Typography>

                        <ChangePeriodLogin setReload={setReload} reload={reload} period={period.JWT_TTL} />
                      </Stack>
                    )}
                  </>
                ) : (
                  ''
                )}
                <TableContainer component={Paper} className="mt-5">
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">User-id</TableCell>
                        <TableCell align="center">Name</TableCell>
                        <TableCell align="center">Email</TableCell>
                        <TableCell align="center">Role</TableCell>
                        <TableCell align="center"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((item) => (
                        <TableRow key={item.id} id={item.id}>
                          <TableCell align="center">{item.id}</TableCell>
                          <TableCell align="center">{item.name}</TableCell>
                          <TableCell align="center">{item.email}</TableCell>
                          <TableCell align="center">{item.role_name} </TableCell>
                          <TableCell align="center">
                            {roles.includes('update-User') && (
                              <UpdateUser id={item.id} i={item} setReload={setReload} reload={reload} />
                            )}
                            {roles.includes('delete-User') && (
                              <DeleteUser id={item.id} setReload={setReload} reload={reload} />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <Typography variant="h6" className="text-center" sx={{ mb: 5 }}>
                {' '}
                No Users List{' '}
              </Typography>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default UserPage;
