import { Helmet } from 'react-helmet-async';
import { Typography, Container, Grid, Stack, Skeleton, Snackbar, Alert } from '@mui/material';
import emptyImage from '../../assets/img/no-image.png';
import { useState, useEffect } from 'react';
import { CATEGORIES, STORAGE } from 'src/constants/url';
import axios from 'axios';
import UpdateCategory from './UpdateCategory';
import CreateCategory from './CreateCategory';
import DeleteCategory from './DeleteCategory';
import { useNavigate } from 'react-router-dom';

export default function CategoryPage() {
  const token = localStorage.getItem('token');
  const roles = localStorage.getItem('permissions');
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [message, setMessage] = useState({ open: false, text: '', type: '' });
  const navigate = useNavigate();

  const FetchCategories = async () => {
    setLoading(true);

    await axios
      .get(CATEGORIES, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setInfo(response.data.data);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigate('/login');
          localStorage.removeItem('token');
        } else {
          setMessage({ open: true, text: 'Failed to fetch categories', type: 'error' });
        }
      });

    setLoading(false);
  };

  useEffect(() => {
    FetchCategories();
  }, [reload]);

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage({ open: false, text: '', type: '' });
  };

  const skeleton = (
    <>
      <Grid item xs={12} sm={6} md={4} sx={{ padding: '0 5px' }}>
        <Skeleton variant="rounded" animation="wave" width="100%" height={250} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} sx={{ padding: '0 5px' }}>
        <Skeleton variant="rounded" animation="wave" width="100%" height={250} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} sx={{ padding: '0 5px' }}>
        <Skeleton variant="rounded" animation="wave" width="100%" height={250} />
      </Grid>
    </>
  );

  return (
    <>
      <Helmet>
        <title> Categories | Digi Dine </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Categories
          </Typography>
          {roles.includes('create-Category') && <CreateCategory setReload={setReload} reload={reload} />}
        </Stack>

        <Grid container spacing={3}>
          {loading ? (
            skeleton
          ) : (
            <>
              {info.length !== 0 ? (
                info.map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item.id} id={item.id}>
                    <div className="box-cat position-relative rounded shadow text-center">
                      <div className="box-img">
                        <img
                          src={item.image != null ? `${STORAGE}${item.image}` : emptyImage}
                          width="100%"
                          className="rounded-top"
                          alt="Category"
                        />
                      </div>
                      <div className="py-3">
                        <Typography variant="h6">Category Name: {item.translations?.name?.en || 'N/A'}</Typography>
                        <Typography variant="h6">
                          Staff Section: {item.staff_sections_id != null ? item.staff_section?.translations?.name?.en || 'N/A' : 'N/A'}
                        </Typography>
                      </div>
                      <div className="position-absolute top-0 end-0 py-2 px-3">
                        {roles.includes('update-Category') && (
                          <UpdateCategory id={item.id} i={item} tr={item.translations?.name} setReload={setReload} reload={reload} />
                        )}
                        {roles.includes('delete-Category') && (
                          <DeleteCategory id={item.id} setReload={setReload} reload={reload} />
                        )}
                      </div>
                    </div>
                  </Grid>
                ))
              ) : (
                <Typography variant="h6" className="text-center" sx={{ mb: 5 }}>
                  NO Categories
                </Typography>
              )}
            </>
          )}
        </Grid>
      </Container>

      <Snackbar open={message.open} autoHideDuration={3000} onClose={handleCloseMessage}>
        <Alert
          onClose={handleCloseMessage}
          severity={message.type}
          sx={{ width: '100%' }}
          style={{ background: message.type === 'success' ? 'rgb(46, 125, 50)' : 'rgb(211, 47, 47)', color: '#fff' }}
        >
          {message.text}
        </Alert>
      </Snackbar>
    </>
  );
}
