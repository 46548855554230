import { useLocation, Navigate, Outlet } from 'react-router-dom';

const RequireAuth = ({ allowedRoles }) => {
  const location = useLocation();
  const userEmail = localStorage.getItem('uesrEmail');

  let roles = [];
  try {
    roles = JSON.parse(localStorage.getItem('permissions')) || [];
  } catch (error) {
    console.error('Error parsing permissions from localStorage:', error);
  }

  if (!userEmail) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (roles.length === 0) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const hasAccess = roles.some((role) => allowedRoles.includes(role));

  if (hasAccess) {
    return <Outlet />;
  }

  return <Navigate to="/unauthorized" state={{ from: location }} replace />;
};

export default RequireAuth;
