import { Helmet } from 'react-helmet-async';
// @mui
import { Typography } from '@mui/material';
// components
import Logo from '../components/logo/Logo';
// sections
import { LoginForm } from '../sections/auth/login';


export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title> Login | Digi Dine </title>
      </Helmet>
      <div className=" bg-login">
        <div className="container">
          <div className='row align-items-center'>
          
            <div className='col-lg-5'>
            
              <Logo />
              <Typography variant="h3">
                Welcome to Digi Dine
              </Typography>
            </div>
        
            <div className='col-lg-7'>
            <div className='box-form'>
            <LoginForm />
            </div>
            </div>
          
            </div>
        </div>
      </div>

    </>
  );
}
