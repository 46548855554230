import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
} from '@mui/material';
import ImageUploading from 'react-images-uploading';
import { ADD_LANG, ITEMS, STORAGE } from 'src/constants/url';
import CategorySection from '../CategorySection';

const UpdateItems = ({ reload, setReload, id, i }) => {
  const [all, setAll] = useState([]);
  const [loadingLang, setLoadingLang] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');
  const [message, setMessage] = useState(false);
  const [price, setPrice] = useState(i.price);
  const [offer_price, setOfferPrice] = useState(i.offer_price != null ? i.offer_price : '');
  const [weight, setWeight] = useState(i.weight);
  const [trending, setTrending] = useState(i.is_trending);
  const [vegan, setVegan] = useState(i.vegan);
  const [lactose, setLactose] = useState(i.lactose_free);
  const [gluten, setGluten] = useState(i.gluten_free);
  const [images, setImages] = useState([]);
  const [category_section, setcategory_section] = useState(i.category != null ? i.category.id : '');
  const [showImage, setshowImage] = useState(i.image);
  const [error, setError] = useState(false);
  const [erroralert, showErrorAlert] = useState([]);
  const [empty, setEmpty] = useState('');
  const [isOfferPriceNull, setIsOfferPriceNull] = useState(i.offer_price == null);

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage(false);
  };

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const FetchAllLang = async () => {
    setLoadingLang(true);
    await axios
      .get(ADD_LANG, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAll(response.data.map(lang => ({
            ...lang,
            inputs: i.translations?.name?.[lang.code] || '',
            desc: i.translations?.description?.[lang.code] || ''
          })));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setLoadingLang(false);
  };

  useEffect(() => {
    FetchAllLang();
  }, []);

  const handleChangeInputs = (e, id) => {
    const { value } = e.target;
    setAll((inputs) =>
      inputs?.map((list) =>
        list.id === id ? { ...list, inputs: value } : list,
      )
    );
  };

  const handleChangeDesc = (e, id) => {
    const { value } = e.target;
    setAll((desc) =>
      desc?.map((list) =>
        list.id === id ? { ...list, desc: value } : list,
      )
    );
  };

  const handleOfferPriceChange = (e) => {
    setOfferPrice(e.target.value);
    setIsOfferPriceNull(false);
  };

  const handleOfferPriceNullChange = (e) => {
    setIsOfferPriceNull(e.target.checked);
    if (e.target.checked) {
      setOfferPrice('');
    }
  };

  const UpdateItemForm = () => {
    setLoading(true);
    const formData = new FormData();
    all.forEach((item) => formData.append(`name[${item.code}]`, item.inputs));
    all.forEach((i) => formData.append(`description[${i.code}]`, i.desc));
    formData.append('price', price);
    formData.append('is_trending', trending);
    formData.append('vegan', vegan);
    formData.append('lactose_free', lactose);
    formData.append('gluten_free', gluten);
    formData.append('weight', weight);
    if (category_section != null) {
      formData.append('category_id', category_section);
    }
    if (images.length != 0) {
      formData.append('image', images[0].file);
    }
    if (isOfferPriceNull) {
      formData.append('offer_price', 'null');
    } else if (offer_price !== '') {
      formData.append('offer_price', offer_price);
    }

    axios
      .post(`${ITEMS}/${id}?_method=PUT`, formData, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(() => {
        setMessage(true);
        setTimeout(() => {
          setReload(!reload);
        }, 1500);
        setOpen(false);
        setError(false);
      })
      .catch((error) => {
        setError(true);
        showErrorAlert(error.response.data.message);
        setEmpty('please add category then try to update item');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen} className="btn-bg border-0 text-white mt-2">
        <ModeEditOutlineOutlinedIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update Item</DialogTitle>
        <DialogContent>
          <>
            {error ? (
              <Alert severity="error" className="mb-4">
                <AlertTitle>Error</AlertTitle>
                <p>{erroralert}</p>
                {category_section != null ? '' : empty}
              </Alert>
            ) : (
              ''
            )}
            <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
              {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                <div className="upload__image-wrapper position-relative mb-3">
                  <button
                    className="btn-add w-100 d-block text-white border-0 py-2"
                    style={isDragging ? { color: 'red' } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                    type="button"
                  >
                    upload Image
                  </button>
                  {imageList.length > 0 ? (
                    imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <img src={image['data_url']} alt="" width="100" />
                        <div className="image-item__btn-wrapper">
                          <button
                            onClick={() => onImageRemove(index)}
                            className="bg-danger border-0 text-white position-absolute bottom-0 end-0"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <img src={`${STORAGE}${showImage}`} width="100%" />
                  )}
                </div>
              )}
            </ImageUploading>
            {loadingLang ? <Skeleton animation="wave" width="100%" /> :
              <>
                {all.length > 0 && all.map((item) => (
                  <TextField
                    id="filled-basic"
                    label={`Item Name ${item.title}`}
                    value={item.inputs}
                    key={item.code}
                    variant="filled"
                    fullWidth
                    onChange={(e) => handleChangeInputs(e, item.id)}
                    className="mb-2"
                  />
                ))}
              </>
            }
            {loadingLang ? <Skeleton animation="wave" width="100%" /> :
              <>
                {all.length > 0 && all.map((i) => (
                  <TextField
                    id="filled-multiline-static"
                    label={`Item Description ${i.title}`}
                    value={i.desc}
                    key={i.code}
                    multiline
                    rows={4}
                    variant="filled"
                    fullWidth
                    onChange={(e) => handleChangeDesc(e, i.id)}
                    className="mb-2"
                  />
                ))}
              </>
            }

            <TextField
              id="filled-basic"
              label="Item Price"
              value={price}
              type="number"
              variant="filled"
              fullWidth
              onChange={(e) => setPrice(e.target.value)}
              className="mb-2"
            />

            <TextField
              id="filled-basic"
              label="Item Offer Price"
              value={offer_price}
              type="number"
              variant="filled"
              fullWidth
              onChange={handleOfferPriceChange}
              className="mb-2"
              disabled={isOfferPriceNull}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isOfferPriceNull}
                  onChange={handleOfferPriceNullChange}
                />
              }
              label="Set Offer Price to Null"
            />

            <TextField
              id="filled-basic"
              label="Item Weight"
              value={weight}
              type="number"
              variant="filled"
              fullWidth
              onChange={(e) => setWeight(e.target.value)}
              className="mb-2"
            />
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                checked={trending === 1}
                onChange={(e) => setTrending(e.target.checked ? 1 : 0)}
                label="Trending"
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={vegan === 1}
                onChange={(e) => setVegan(e.target.checked ? 1 : 0)}
                label="Vegan"
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={lactose === 1}
                onChange={(e) => setLactose(e.target.checked ? 1 : 0)}
                label="Lactose Free"
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={gluten === 1}
                onChange={(e) => setGluten(e.target.checked ? 1 : 0)}
                label="Gluten Free"
              />
            </FormGroup>
            <CategorySection category_section={category_section} setcategory_section={setcategory_section} />
          </>
        </DialogContent>
        <DialogActions className="mb-4">
          {loading ? (
            <Button className=" btn-bg">
              <CircularProgress style={{ color: '#fff' }} size={30} />
            </Button>
          ) : (
            <Button className="btn-bg text-white fs-6" onClick={UpdateItemForm}>
              Save
            </Button>
          )}
          <Button onClick={handleClose} className="btn-bg bg-secondary text-white fs-6">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: '100%' }}
          style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
        >
          Success! Item Updated Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default UpdateItems;
