import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { updateFcmToken } from "./constants/url";

const firebaseConfig = {
  apiKey: "AIzaSyAugCpn-wmQkOBUGar5sa3MLWHPpwgAIrQ",
  authDomain: "test-menu-6f51d.firebaseapp.com",
  projectId: "test-menu-6f51d",
  storageBucket: "test-menu-6f51d.appspot.com",
  messagingSenderId: "439403997453",
  appId: "1:439403997453:web:e0a02275a06b5b7fdb6844",
  measurementId: "G-0MJ3K35STR"
};

initializeApp(firebaseConfig); 

const messaging = getMessaging();

export const requestForToken = async (token) => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BKS7sym70srmAhiwLYquR5cNvtAiQYdPgipLwCUdmiCp5AlOqbFq7S-Aj0vWmAZ8M-gZuw7LePOoBBRJ2I4JFyY",
    });
    if (currentToken) {
      if (token) {
        axios.post(
          updateFcmToken,
          { fcm_token: currentToken },
          {
            headers: {
              Authorization: "Bearer " + token,
              Accept: "application/json",
            },
          }
        );
      }
      // .then((response) => {
      //   console.log(response);
      // })
      // .catch((error) => {
      //   console.log(error);
      // });
    } else {
      // Show permission request UI
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
