import { Helmet } from 'react-helmet-async';
import { Typography, Container, Grid, Stack, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { KITCHEN_SECTION } from 'src/constants/url';
import DeleteKitchen from './DeleteKitchen';
import UpdateKitchen from './UpdateKitchen';
import CreateKitchen from './CreateKitchen';
const KitchenPage = () => {
  const token = localStorage.getItem('token');
  const roles = localStorage.getItem('permissions');
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();

  const FetchStaffSection = async () => {
    setLoading(true);
    if (token != null) {
      await axios
        .get(KITCHEN_SECTION, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setInfo(response.data.data);
          } else if (response.status === 401 || response.status === 403) {
            navigate('/', { replace: true });
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    } else {
      navigate('/login');
    }
    setLoading(false);
  };

  useEffect(() => {
    FetchStaffSection();
  }, [reload]);

  const skeleton = (
    <>
      <Grid item xs={12} sm={6} md={4} sx={{ padding: '0 5px' }}>
        <Skeleton variant="rounded" animation="wave" width="100%" height={250} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} sx={{ padding: '0 5px' }}>
        <Skeleton variant="rounded" animation="wave" width="100%" height={250} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} sx={{ padding: '0 5px' }}>
        <Skeleton variant="rounded" animation="wave" width="100%" height={250} />
      </Grid>
    </>
  );
  return (
    <>
      <Helmet>
        <title> Staff Section | Digi Dine </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Staff Section
          </Typography>
          {roles.includes('create-StaffSection') && <CreateKitchen reload={reload} setReload={setReload} />}
        </Stack>
        <Grid container spacing={3}>
          {loading ? (
            skeleton
          ) : (
            <>
              {info.length != 0 ? (
                info.map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item.id} id={item.id}>
                    <div className="box-cat position-relative rounded shadow text-center">
                      <div>
                        <Typography variant="h6" className="pt-4">
                          {item.translations.name.en}
                        </Typography>
                      </div>
                      <div className="py-4 px-3">
                        {roles.includes('update-StaffSection') && (
                          <UpdateKitchen reload={reload} setReload={setReload} id={item.id} i={item} />
                        )}
                        {roles.includes('delete-StaffSection') && (
                          <DeleteKitchen reload={reload} setReload={setReload} id={item.id} />
                        )}
                      </div>
                    </div>
                  </Grid>
                ))
              ) : (
                <Typography variant="h6" className="text-center" sx={{ mb: 5 }}>
                  {' '}
                  NO Staff Sections Yet{' '}
                </Typography>
              )}
            </>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default KitchenPage;
