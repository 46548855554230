import { Helmet } from 'react-helmet-async';
import {
  Typography,
  Container,
  Stack,
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { ITEMS, STORAGE } from 'src/constants/url';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteItem from './DeleteItem';
import CreateItem from './CreateItem';
import UpdateItems from './UpdateItems';
import Label from 'src/components/label/Label';

const ItemsPage = () => {
  const token = localStorage.getItem('token');
  const roles = JSON.parse(localStorage.getItem('permissions') || '[]');
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const [info, setInfo] = useState([]);
  const [error, setError] = useState(null);

  const FetchItems = async () => {
    setLoading(true);
    setError(null);
    if (token != null) {
      try {
        const response = await axios.get(ITEMS, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        });
        if (response.status === 200) {
          setInfo(response.data.data);
        } else if (response.status === 401) {
          navigate('/', { replace: true });
        }
      } catch (error) {
        setError(error.response?.data?.message || 'An error occurred while fetching items.');
      }
    } else {
      navigate('/login');
    }
    setLoading(false);
  };

  useEffect(() => {
    FetchItems();
  }, [reload]);

  return (
    <>
      <Helmet>
        <title> Items | Digi Dine </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Items
          </Typography>
          {roles.includes('create-Item') && <CreateItem setReload={setReload} reload={reload} />}
        </Stack>
        {loading ? (
          <Skeleton animation="wave" height={500} variant="rectangular" />
        ) : error ? (
          <Typography variant="h6" className="text-center" sx={{ mb: 5, color: 'red' }}>
            {error}
          </Typography>
        ) : (
          <>
            {info.length > 0 ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Image</TableCell>
                      <TableCell align="center">Category</TableCell>
                      <TableCell align="center">Price</TableCell>
                      <TableCell align="center">Offer Price</TableCell>
                      <TableCell align="center">Weight</TableCell>
                      <TableCell align="center">Description</TableCell>
                      <TableCell align="center">Tags</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {info.map((item) => (
                      <TableRow key={item.id} id={item.id}>
                        <TableCell align="center">{item.translations.name.en}</TableCell>
                        <TableCell align="center">
                          <Link to={`${STORAGE}${item.image}`} target="_bank">
                            <VisibilityIcon />
                          </Link>
                        </TableCell>
                        <TableCell align="center">
                          {item.category ? item.category.translations.name.en : '--'}
                        </TableCell>
                        <TableCell align="center">{item.price}</TableCell>
                        <TableCell align="center">{item.offer_price != null ? item.offer_price : '--'}</TableCell>
                        <TableCell align="center">{item.weight}</TableCell>
                        <TableCell align="center">{item.description}</TableCell>
                        <TableCell align="center">
                          <Stack spacing={1}>
                            <Label
                              variant="ghost"
                              sx={{
                                color: item.is_trending ? 'green' : 'red',
                              }}
                            >
                              trending: {item.is_trending ? 'yes' : 'no'}
                            </Label>
                            <Label
                              variant="ghost"
                              sx={{
                                color: item.vegan ? 'green' : 'red',
                              }}
                            >
                              vegan: {item.vegan ? 'yes' : 'no'}
                            </Label>
                            <Label
                              variant="ghost"
                              sx={{
                                color: item.lactose_free ? 'green' : 'red',
                              }}
                            >
                              lactose free: {item.lactose_free ? 'yes' : 'no'}
                            </Label>
                            <Label
                              variant="ghost"
                              sx={{
                                color: item.gluten_free ? 'green' : 'red',
                              }}
                            >
                              gluten free: {item.gluten_free ? 'yes' : 'no'}
                            </Label>
                          </Stack>
                        </TableCell>
                        <TableCell align="center">
                          <Stack>
                            {roles.includes('update-Item') && (
                              <UpdateItems id={item.id} i={item} setReload={setReload} reload={reload} />
                            )}
                            {roles.includes('delete-Item') && (
                              <DeleteItem id={item.id} setReload={setReload} reload={reload} />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="h6" className="text-center" sx={{ mb: 5 }}>
                No items yet
              </Typography>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default ItemsPage;
