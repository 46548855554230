import { Helmet } from 'react-helmet-async';
import { Typography, Container, Grid, Skeleton, Stack, Divider, Link } from '@mui/material';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ABOUT } from 'src/constants/url';
import {
  Title as TitleIcon,
  Description as DescriptionIcon,
  LocationOn as LocationOnIcon,
  Call as CallIcon,
  Wifi as WifiIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
  WhatsApp as WhatsAppIcon,
} from '@mui/icons-material';
import { Icon } from '@iconify/react';
import UpdateAbout from './UpdateAbout';
import { useNavigate } from 'react-router-dom';

const AboutPage = () => {
  const token = localStorage.getItem('token');
  let roles = [];

  try {
    const permissions = localStorage.getItem('permissions');
    roles = permissions ? JSON.parse(permissions) : [];
  } catch (error) {
    console.error('Error parsing permissions from localStorage:', error);
  }

  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();

  const FetchInfoAbout = async () => {
    try {
      const response = await axios.get(ABOUT, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
      if (response.status === 200) {
        setInfo(response.data.data);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        navigate('/login');
        localStorage.removeItem('token');
      } else {
        console.error('Error fetching about info:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchInfoAbout();
  }, [reload]);

  const sanitizeUrl = (url) => {
    try {
      const sanitizedUrl = new URL(url);
      return sanitizedUrl.href;
    } catch (e) {
      return `https://${url}`;
    }
  };

  return (
    <>
      <Helmet>
        <title>About | Digi Dine</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            About Digi Dine
          </Typography>
        </Stack>
        {loading ? (
          <Skeleton animation="wave" height={500} variant="rectangular" />
        ) : info ? (
          <Grid container spacing={3} className="about-grid">
            <Grid item xs={12}>
              <Typography variant="h6">
                <TitleIcon /> Title: {info.translations?.title?.en}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                <DescriptionIcon /> Bio: {info.translations?.bio?.en}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">
                <LocationOnIcon /> Address: {info.address}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">
                <CallIcon /> Telephone: {info.tel}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">
                <WifiIcon /> Wifi: {info.wifi}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">
                <FacebookIcon />
                <Link href={sanitizeUrl(info.facebook)} target="_blank" rel="noopener noreferrer">
                  Facebook
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">
                <InstagramIcon />
                <Link href={sanitizeUrl(info.instagram)} target="_blank" rel="noopener noreferrer">
                  Instagram
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">
                <YouTubeIcon />
                <Link href={sanitizeUrl(info.youtube)} target="_blank" rel="noopener noreferrer">
                  YouTube
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">
                <WhatsAppIcon /> Whatsapp: {info.whatsapp}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">
                <Icon icon="ri:snapchat-fill" />
                <Link href={sanitizeUrl(info.snapchat)} target="_blank" rel="noopener noreferrer">
                  Snapchat
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">
                <Icon icon="ic:baseline-tiktok" />
                <Link href={sanitizeUrl(info.tiktok)} target="_blank" rel="noopener noreferrer">
                  Tiktok
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {roles.includes('update-About') && (
                <UpdateAbout i={info} setReload={setReload} reload={reload} />
              )}
            </Grid>
          </Grid>
        ) : (
          <Typography variant="h6" className="text-center" sx={{ mb: 5 }}>
            NO information
          </Typography>
        )}
      </Container>
    </>
  );
};

export default AboutPage;
